

import { mapState, mapMutations } from 'vuex';
export default {
  name: 'FirstStep',
  data() {
    return {

    }
  },


  computed:
    {

    name: {
      get() {
        return this.$store.state.stepsUserModule.searchName
      },

      set(value) {
        if (value.length < 3) {
          this.$store.commit('validateName', false)
        } else {
          this.$store.commit('validateName', true)
          this.$store.commit('stepsUserModule/SET_SEARCH_USER_NAME', value )
        }

      }
    },

    email: {
      get() {
        return this.$store.state.stepsUserModule.searchEmail
      },

      set(value) {
        if (!this.validateEmail(value)) {
          this.$store.commit('validateEmail', false)
        } else {
          this.$store.commit('validateEmail', true)
          this.$store.commit('stepsUserModule/SET_SEARCH_USER_EMAIL', value )
        }
      }
    },


  },


  methods: {
    validateEmail(email) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
}
